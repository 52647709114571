.company {
    align-items: center;
    
    &__img {
        @include media-breakpoint-down(sm) {
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
        }
    }
}