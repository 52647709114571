/* ==========================================================================
   #SECTION
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

.section {
  @include clearfix; // padding: 0 10px;
}

.section--header {
  position: relative;
  padding-top: 10px;
  padding-bottom: 15px;
  z-index: 2;
  background: #fff;

  @include media-breakpoint-up(lg) {
    padding-bottom: 40px;
    border-bottom: 1px solid #e9e9e9;
  }
}

.section--menu {
  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
    margin-top: -25px;
  }
}

.section--billboard {
  padding-top: 20px;
  background: url("../img/bg-billboard.jpg") center no-repeat; // background-color: rgba(255, 255, 255, 0.75);
  background-size: cover;
  & > .container,
  & > .container > .row {
    height: 100%;
  }
  @include media-breakpoint-up(md) {
    height: 433px;
    padding-top: 50px;
  }
  @include media-breakpoint-up(lg) {
    margin-top: -22px;
  }
}

.section--tinted {
  background-color: #f7f7f7;
}

.section--map {
  position: relative;
  min-height: 450px;
  .map {
    height: 450px;
    width: 100%;
    border-top: 6px solid #bd2d28;
    @include media-breakpoint-up(sm) {
      position: absolute;
    }
  }
}

.section--footer {
  background: #125e0e;
}

.section--under-menu {
  border-top: 1px solid #e9e9e9;
  padding-top: 10px;
  @include media-breakpoint-up(lg) {
    padding-top: 50px;
    margin-top: -23px;
  }
}

.section--fixed-top {
  @include media-breakpoint-down(md) {
    position: fixed;
    top: 50px;
    z-index: 1;
  }
}

.section--absolut-top {
  @include media-breakpoint-down(md) {
    position: absolute;
    z-index: 1;
  }
}

.section--contacts {
  margin-bottom: -1px;
}
