.mobile-abolute {
    position: absolute;
    border-radius: 2px;
    z-index: 10;
    top: 100%;
    left: 0;
    width: 100%;
    text-align: center;
    @include media-breakpoint-up(lg) {
        margin-top: 0px;
        position: initial;
    }
}

.mobile-menu {
    
}