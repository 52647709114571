.menu {
  display: none;

  @include media-breakpoint-up(lg) {
    background-color: $color-primary;
    width: 100%;
  }
}

.menu__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    background-color: #196e15;
  }
}

.menu__item {
  flex: 1 1 auto;
  text-align: center;
  position: relative;
  display: block;
  // align-items: center;
  // justify-content: center;

  &--home {
    flex-grow: 0;

    & .menu__link {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &::after {
    content: "";
    display: table;
    position: absolute;
    width: calc(100% - 40px);
    bottom: -1px;
    left: 0;
    margin-left: 20px;
    margin-right: 20px;
    border-bottom: 1px solid #145911;
    @include media-breakpoint-up(lg) {
      content: none;
    }
  }

  &:last-child {
    &::after {
      content: none;
    }
  }

  &:hover {
    background-color: darken($color-primary, 5%);
    @include media-breakpoint-up(lg) {
      > .menu__submenu {
        display: block;
      }
    }
  }
}

.menu__link,
.menu__sublink,
.menu__subsublink {
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
  padding: 10px 10px 12px;
  display: block;
  transition: $global-transition;
  position: relative;

  @include media-breakpoint-up(lg) {
    border-left: solid 1px darken($color-primary, 5%);
  }

  .menu__item:first-child & {
    border-left: flex;
  }
}

.menu__click {
  @include media-breakpoint-down(md) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
  }
}

.menu__down {
  height: 19%;
  position: absolute;
  transform: translate(80px);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;
  cursor: pointer;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    top: calc(50% - (8.7px / 2));
    left: calc(50% - (16px / 2));
  }

  img {
    height: 100%;
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.open .menu__down {
  transform: rotate(180deg) translate(-80px);
}

.menu__item.open {
  background-color: darken($color-primary, 5%);
}

.open {
  .menu__submenu--sub {
    background-color: darken($color-primary, 8%);
  }
}

.menu__submenu {
  display: none;
  flex-direction: column;
  margin: 0;
  list-style: none;
  padding-bottom: 10px;

  &--sub {
    @include media-breakpoint-up(xs) {
      display: block;
    }
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  @include media-breakpoint-up(lg) {
    min-width: 100%;
    white-space: nowrap;
    text-align: left;
    position: absolute;
    background-color: #f7f7f7;
    z-index: 1;
    padding-bottom: 0;

    &--sub {
      top: 0;
      left: 100%;

      .menu__subitem:hover {
        .menu__subsublink {
          color: #ffffff;
          background: $color-primary;
        }
      }
    }
  }
}

.menu__subitem {
  position: relative;

  &:hover .menu__submenu {
    display: block;
  }

  @include media-breakpoint-up(lg) {
    &::after {
      content: "";
      display: table;
      position: absolute;
      width: calc(100% - 40px);
      bottom: -1px;
      left: 0;
      margin-left: 20px;
      margin-right: 20px;
      border-bottom: 1px solid #ffffff;
    }

    &:last-child {
      &::after {
        content: none;
      }
    }
  }

  &:hover {
    .menu__click {
      .menu__sublink {
        color: #ffffff;
        background: $color-primary;
      }
    }
    .menu__open-sub {
      background: $color-primary;

      .menu__sublink {
        color: #fff;
      }

      img {
        filter: none;
      }
    }
  }
}

.menu__sublink,
.menu__subsublink {
  font-family: $font-family-copy;
  border: none;
  padding: 5px 17px;
  color: #fff;
  font-size: 15px;

  @include media-breakpoint-up(lg) {
    color: #333;
    padding: 14px 17px;
    width: 100%;
  }
}

.menu__open-sub {
  @include media-breakpoint-up(xs) {
    img {
      display: none;
    }
  }

  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    img {
      display: block;
      transform: rotate(-90deg);
      width: 5.5%;
      padding-bottom: 30px;
      filter: grayscale(100%) brightness(35%);
    }
  }
}
