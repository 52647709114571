.gallery {
    margin-bottom: 6px;
    margin-left: 3px;
    margin-right: 3px;
    position: relative;
    min-width: 160px;
    @include media-breakpoint-up(lg) {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            width: 100%;
        }
        width: auto;
        margin-right: 3px;
        // margin-bottom: 0;
        &:last-child {
            margin-right: 0;
        }
    }
    &__link {
        &::after {
            opacity: 0;
            background-color: rgba(0, 0, 0, .6);
            transition: opacity .8s cubic-bezier(.19, 1, .22, 1);
            display: block;
            height: 100%;
            z-index: 2;
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
        }
        &:hover {
            &::after {
                opacity: 1;
            }
            &::before {
                left: 50%;
                position: absolute;
                top: 50%;
                background: url(../img/icons/magnifier.png) no-repeat;
                width: 35px;
                height: 35px;
                margin: -18px 0 0 -18px;
                content: "";
                display: block;
                z-index: 20;
            }
        }
    }
}