.icon-item {
    margin-bottom: 34px;
    align-items: center;

    &__icon {
        width: 65px;
    }
    &__info {
        font-size: 15px;
        line-height: 17px;
        font-family: 'Clear Sans', sans-serif;
        font-weight: 500;
    }
}