.list-pages {
    border-top: 1px solid #e9e9e9;
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
    padding-bottom: 40px;
    &__items {
        margin: 0;
        display: flex;
        list-style: none;
        font-family: "GoodCyr-WideBook", sans-serif;
    }
    &__item {
        padding-left: 12px;
    }
    &__link {
        color: $color-text;
        text-decoration: none;

        &:hover {
            color: $color-secondary;
            text-decoration: underline;
        } 
    }
}