.product {
    &__img {
        background: #f7f7f7;
        img {
            padding: 35px 25px;
            width: 100%;
        }
    }

    &__description {
        font-size: 15px;
        ul {
            list-style: none;
            margin: 0;
            margin-bottom: 25px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}