///* ========================================================================
//   #FONTS
//   ======================================================================== */

// Переменные, содержащие font-family для всех подключенных шрифтов
$font-family-clear-sans: 		"Clear Sans", sans-serif;
$font-family-goodcyr-widebook: 	"GoodCyr-WideBook", sans-serif;
$font-family-goodcyr-wideblack: "GoodCyr-WideBlack", sans-serif;

// Переменные, содержашие font-family по назначению
$font-family-copy:		$font-family-clear-sans;
$font-family-heading: 	$font-family-clear-sans;
$font-family-alt: 		$font-family-goodcyr-widebook;

$font-size-default: 14px;

