.breadcrumbs {
    margin-left: 0;
    list-style: none;
    color: $color-primary;
    margin-bottom: 5px;

    &__item {
        padding-right: 6px;
        display: inline;
    }

    &__link {
        color: $color-primary;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}