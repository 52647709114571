.contacts {
    display: none;
}

.contacts__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    @include media-breakpoint-down(md) {
        flex-direction: column;
        background-color: #bd2d28;
    }
}

.contacts__item {
    flex: 1 1 auto;
    text-align: center;
    position: relative;
    
    &::after {
        content: "";
        display: table;
        position: absolute;
        width: calc(100% - 40px);
        bottom: -1px;
        left: 0;
        margin-left: 20px;
        margin-right: 20px;
        border-bottom: 1px solid #7e1e1b;
        @include media-breakpoint-up(lg) {
            content: initial;
        }
    }
    &:last-child {
        &::after {
            content: none;
        }
    }
}

.contacts__link {
    text-decoration: none;
    padding: 10px;
    display: block;
    transition: $global-transition;
    &:hover {
        background-color: darken(#bd2d28, 5%);
    }
}

.contacts__button {
    font-weight: normal;
}