.footer{
    display: flex;
    justify-content: space-between;
    color: white;
    font-size: 17px;
    line-height: 1.3;

    a {
        color: white;
    }
}