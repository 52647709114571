.advantages {
    padding-bottom: 10px;

    @include media-breakpoint-up(lg) {
        background: url(../img/advantage.jpg) center center no-repeat;
    }

    &__column {
        width: 300px;
        @include media-breakpoint-up(md) {
            width: auto;
        }
    }
}

    