///* ========================================================================
//   #FONT-FACES
//   ======================================================================== */

@font-face {
	font-family: "Clear Sans";
	src: url("../fonts/subset-ClearSans-Medium.eot");
	src: url("../fonts/subset-ClearSans-Medium.eot?#iefix") format("embedded-opentype"),
		url("../fonts/subset-ClearSans-Medium.woff2") format("woff2"),
		url("../fonts/subset-ClearSans-Medium.woff") format("woff"),
		url("../fonts/subset-ClearSans-Medium.ttf") format("truetype"),
		url("../fonts/subset-ClearSans-Medium.svg#ClearSans-Medium") format("svg");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Clear Sans";
	src: url("../fonts/subset-ClearSans-Light.eot");
	src: url("../fonts/subset-ClearSans-Light.eot?#iefix") format("embedded-opentype"),
		url("../fonts/subset-ClearSans-Light.woff2") format("woff2"),
		url("../fonts/subset-ClearSans-Light.woff") format("woff"),
		url("../fonts/subset-ClearSans-Light.ttf") format("truetype"),
		url("../fonts/subset-ClearSans-Light.svg#ClearSans-Light") format("svg");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "GoodCyr-WideBlack";
	src: url("../fonts/subset-GoodCyr-WideBlack.eot");
	src: url("../fonts/subset-GoodCyr-WideBlack.eot?#iefix") format("embedded-opentype"),
		url("../fonts/subset-GoodCyr-WideBlack.woff2") format("woff2"),
		url("../fonts/subset-GoodCyr-WideBlack.woff") format("woff"),
		url("../fonts/subset-GoodCyr-WideBlack.ttf") format("truetype"),
		url("../fonts/subset-GoodCyr-WideBlack.svg#GoodCyr-WideBlack") format("svg");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "GoodCyr-WideBook";
	src: url("../fonts/subset-GoodCyr-WideBook.eot");
	src: url("../fonts/subset-GoodCyr-WideBook.eot?#iefix") format("embedded-opentype"),
		url("../fonts/subset-GoodCyr-WideBook.woff2") format("woff2"),
		url("../fonts/subset-GoodCyr-WideBook.woff") format("woff"),
		url("../fonts/subset-GoodCyr-WideBook.ttf") format("truetype"),
		url("../fonts/subset-GoodCyr-WideBook.svg#GoodCyr-WideBook") format("svg");
	font-weight: 500;
	font-style: normal;
}
