.blackout {
  @include media-breakpoint-up(lg) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: none;
    z-index: 1;
  }
}

.blackout.active {
  @include media-breakpoint-up(lg) {
    display: block;
  }
}
