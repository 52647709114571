.user-content {
    h2 {
        text-align: center;
        @extend .heading-h1;
    }
    a {
        color: $color-primary;
    }
    p {

        img {
            float: left;
            margin: 0 64px 16px 0;
        }
        strong {
            font-size: 110%;
        }
    }
}