.bid {
    background: #f7f7f7;

    & :last-child {
        margin-bottom: 0;
    }
    
    &__info {
        margin-right: 10px;

        & p,
        & ol,
        & ul
        {
            font-size: 17px;
        }
    }
}