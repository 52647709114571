/* ==========================================================================
   #SECTION
   ========================================================================== */

.billboard {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(md) {
        justify-content: flex-end;
        align-items: center;
    }
}

.billboard__info {
    margin-bottom: 20px;
    text-align: center; // background-color: rgba(255, 255, 255, 0.75);
    @include media-breakpoint-up(md) {
        max-width: 60%;
    }
    @include media-breakpoint-down(sm) {
        background: rgba(255, 255, 255, 0.6);
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
		padding: 15px;
		font-weight: 500;
    }
}

.billboard__title {
    font-family: $font-family-goodcyr-wideblack;
    line-height: 1;
    text-transform: uppercase; // display: inline-block;
    padding-bottom: 10px;
    @include media-breakpoint-up(xs) {
		font-size: 140%;
		word-wrap: break-word;
	}
	@include media-breakpoint-up(sm) {
		font-size: 22px;
	}
    @include media-breakpoint-up(md) {
        font-size: 32px;
    }
}

.billboard__desc {
    line-height: 1;
    display: inline-block;
    padding-bottom: 15px;
}

.billboard--space {
    margin-top: 150px !important;
    @include media-breakpoint-up(sm) {
        margin-top: 110px !important;
    }
    @include media-breakpoint-up(lg) {
        margin-top: -20px !important;
    }
}