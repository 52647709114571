
.u-padding-left-md {
    @include media-breakpoint-up(md) {
        padding-left: 100px;
    }
}
.u-padding-right-md {
    @include media-breakpoint-up(md) {
        padding-right: 100px;
    }
}