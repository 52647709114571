.list-category {
  margin-left: 0;
  list-style: none;
  background: #f7f7f7;
  font-family: "GoodCyr-WideBook", sans-serif;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 1.1;

  &__link,
  &__sublink {
    display: block;
    padding: 22px;
    position: relative;
    text-decoration: none;
    color: $color-text;

    &:after {
      content: "";
      display: table;
      position: absolute;
      width: calc(100% - 16px);
      bottom: 0;
      left: 0;
      margin-left: 8px;
      margin-right: 8px;
      border-bottom: 1px solid #ffffff;
    }
  }

  &__item {
    &:hover {
      background: $color-primary;

      .list-category__link {
        color: #fff;

        &:after {
          content: none;
        }
      }

      .list-category__submenu {
        display: flex;
      }
    }

    &--active {
      background: $color-primary;

      .list-category__link {
        color: #fff;

        &:after {
          content: none;
        }
      }

      .list-category__submenu {
        display: flex;
      }
    }
  }

  &__submenu {
    display: none;
    list-style: none;
    margin: 0;
    flex-direction: column;
    padding-bottom: 15px;
  }

  &__sublink {
    color: #fff;
    text-transform: capitalize;
    font-size: 15px;
    padding-top: 5px;
    padding-bottom: 5px;

    &:hover {
      text-decoration: underline;
    }

    &:after {
      content: none;
    }
  }
}
