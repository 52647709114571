/* ==========================================================================
   #SECTION
   ========================================================================== */


/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

.header {
    // padding: 15px 0 25px;
}

.header__wrapper {
    display: flex;
    align-items: center;
}

.header__img {
    @media (max-width: 319px) {
        width: 150px;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @include media-breakpoint-up(xs) {
            width: 50vw;
        }
        @include media-breakpoint-up(sm) {
            width: auto;
        }
    }
}

.header--fixed {
    @include media-breakpoint-down(md) {
        position: fixed !important;
        top: 0;
        width: 100%;
        background: #fff;
        z-index: 1;
    }
}

.header__contact {
    min-width: 70px;
}