.contact {
    margin: 0;
    font-size: 20px;
    line-height: 1;
    font-weight: bold;
    letter-spacing: -0.03em;
}

.contact--em {
    font-size: 23px;
    font-family: $font-family-goodcyr-wideblack;
    color: $color-primary;
    font-weight: inherit;
    letter-spacing: 0;
}

.contact--popup {
    letter-spacing: -0.03em;
    font-weight: normal;
    color: #fff;
    font-size: 18px;
}