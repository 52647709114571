.list-products {
    list-style: none;
    margin-left: 0;
    &__item {
        display: flex;
        justify-content: space-between;
        padding: 25px 15px;
        flex-direction: column;
        &:nth-child(odd) {
            background-color: #f7f7f7;
        }
    
        @include media-breakpoint-up(md) {
            flex-direction: row;
        }
    }
    &__img {
        display: flex;
        justify-content: center;
        align-self: center;
        width: auto;
        margin-bottom: 20px;
       
        @include media-breakpoint-up(md) {
            width: (100% / 3);
            margin-bottom: 0;
        }
    }
    &__info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: auto;
        margin-bottom: 20px;
        align-self: center;
        
        @include media-breakpoint-up(md) {
            width: (100% / 3);
            margin-bottom: 0;
            align-self: auto;
        }
    }
    &__header {
        color: #333333;
        margin-bottom: 20px;
        line-height: 1.1;
        font-size: 17px;
    }
    &__heading {
        font-family: "GoodCyr-WideBook", sans-serif;
        text-transform: uppercase;
    }
    &__link {
        text-decoration: none;
        color: #fff;
    }
    &__footer {
        align-self: center;
    }
}