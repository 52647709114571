.table {
    overflow: scroll;
    @include media-breakpoint-up(md) {
        overflow: auto;
    }
}

table {
    font-family: $font-family-copy;
    text-align: left;
    min-width: 550px;
    font-size: 17px;

    td, th {
        color: #333;
        border-bottom: 1px solid #aaa;
        @include media-breakpoint-up(xs) {
            padding: 10px;
        }
        @include media-breakpoint-up(md) {
            padding: 15px;
        }
    }
    
    th {
        font-weight: 300 !important;
        color: #fff;
        background-color: #196e15;
        border-right: 1px solid #1a5d16;
        border-bottom: none;
        &:last-child {
            border: none;
        }
    }
}