.service {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    flex-basis: 185px;
    padding: 15px 0;
    border: 1px solid transparent;
    box-sizing: border-box;
    text-decoration-line: none;

    &:last-child {
        @include media-breakpoint-up(lg) {
            flex-grow: 0;
        }
        @include media-breakpoint-up(xl) {
            flex-grow: 1;
        }
    }
    &:hover {
        cursor: pointer;
    }
    &:hover &__icon {
        filter: brightness(0.8);
    }
    &:hover &__info {
        text-decoration: underline;
    }

    &__info {
        font-size: 15px;
        text-align: center;
        color: #735630;
        line-height: 1;
        margin: 0;
        margin-top: 20px;;
    } 
}