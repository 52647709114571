$button-width: 30px;
$button-height: 30px;
$bar-thickness: 6px * ($button-width / 108px);
$button-pad: 30px * ($button-width / 108px);
$button-trans-delay: 0.3s;


.toggle-humburger {
    display: block;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: $button-width;
    height: $button-height;
    font-size: 0;
    text-indent: -9999px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
    transition: background $button-trans-delay;
  }
  
  .toggle-humburger:focus {
    outline: none;
  }
  
  .toggle-humburger span {
    /* display: block;
    position: absolute;
    top: ($button-height / 2) - ($bar-thickness / 2);
    left: $button-pad;
    right: $button-pad;
    height: $bar-thickness;
    background: rgb(255,255,255); */
    display: block;
    position: absolute;
    top: 15px;
    left: 9px;
    right: 9px;
    height: 2px;
    background: white;
  }
  
  .toggle-humburger span::before,
  .toggle-humburger span::after {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    /* height: $bar-thickness; */
    height: 2px;
    background-color: #fff;
    content: "";
  }
  
  .toggle-humburger span::before {
    /* top: -(($button-height / 2) - ($bar-thickness / 2) - $button-pad); */
    top: -5px;
  }
  
  .toggle-humburger span::after {
    /* bottom: -(($button-height / 2) - ($bar-thickness / 2) - $button-pad); */
    bottom: -5px;
  }

  .toggle-humburger__htx {
    background-color: #196e15;
  }
  
  .toggle-humburger__htx span {
    transition: background 0 $button-trans-delay;
  }
  
  .toggle-humburger__htx span::before,
  .toggle-humburger__htx span::after {
    transition-duration: $button-trans-delay, $button-trans-delay;
    transition-delay: $button-trans-delay, 0;
  }
  
  .toggle-humburger__htx span::before {
    transition-property: top, transform;
  }
  
  .toggle-humburger__htx span::after {
    transition-property: bottom, transform;
  }
  
  /* active state, i.e. menu open */
  
  .toggle-humburger__htx.active {
    background-color: #145911;
  }
  
  .toggle-humburger__htx.active span {
    background: none;
  }
  
  .toggle-humburger__htx.active span::before {
    top: 0;
    transform: rotate(45deg);
  }
  
  .toggle-humburger__htx.active span::after {
    bottom: 0;
    transform: rotate(-45deg);
  }
  
  .toggle-humburger__htx.active span::before,
  .toggle-humburger__htx.active span::after {
    transition-delay: 0, $button-trans-delay;
  }