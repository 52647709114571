.heading-h1 {
	font-family: $font-family-copy;
	font-weight: 100;
	text-transform: uppercase;
	line-height: 1;
	@include media-breakpoint-up(xs) {
		font-size: (40px * 0.9);
		word-wrap: break-word;
	}
	@include media-breakpoint-up(sm) {
		font-size: 40px;
	}
}

/* .heading-h2 {

} */

.heading-h3 {
	font-family: 'GoodCyr-WideBook', sans-serif;
	font-size: 17px;
	line-height: 1;
	letter-spacing: 0.015;
	text-transform: uppercase;
	font-weight: normal;
	margin-bottom: 10px;
}