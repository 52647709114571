.contact-data {
    background:#bd2d28;
    padding: 40px 25px;
    color: white;
    text-align: center;

    span {
        font-size: 17px;
        line-height: 1;
    }
    
    hr {
        margin: 26px 0;
        color: #a6221e;
        background-color: #a6221e;
        border: 0px none;
        height: 1px;
    }

    &__tel {
        a {
            color: white;
            text-decoration: none;
            

            &:hover {
                text-decoration: underline
            }
        }
    }
}