.card {
  border-radius: $global-radius;
  padding-top: 30px;
  padding-bottom: 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: background-color 100ms;

  &:hover {
    background-color: $color-secondary;
    color: #ffffff;
  }

  &:before {
    @include media-breakpoint-up(sm) {
      content: "";
      display: table;
      position: absolute;
      height: calc(100% - 40px);

      top: 0;
      right: -1px;
      margin-top: 20px;
      margin-bottom: 20px;

      border-right: 1px solid #ffffff;
    }
  }

  &:after {
    content: "";
    display: table;
    position: absolute;
    width: calc(100% - 40px);

    bottom: -1px;
    left: 0;
    margin-left: 20px;
    margin-right: 20px;

    border-bottom: 1px solid #ffffff;
  }

  @include media-breakpoint-between(sm, md) {
    &:nth-of-type(2n) {
      &:before {
        content: none;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &:nth-of-type(3n) {
      &:before {
        content: none;
      }
    }
  }
}

.card__header {
  flex: 1 1 auto;
}

.card__heading {
  font-family: $font-family-alt;
  font-size: 17px;
  text-transform: uppercase;
  color: $color-primary;
  line-height: 1;
  margin-bottom: 10px;

  & ~ .card__subheading {
    margin-top: -10px;
  }

  .card:hover & {
    color: #ffffff;
  }
}

.card__subheading {
  font-size: 17px;
  color: $color-primary;
  line-height: 1;
  margin-bottom: 10px;

  .card:hover & {
    color: #ffffff;
  }
}

.card__img {
  margin-bottom: 10px;
}

.card__desc {
  line-height: 1;
}

.card__footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  display: none;
  margin-bottom: 10px;

  .card:hover & {
    display: block;
  }
}

.card__link {
  color: #ffffff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
