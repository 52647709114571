.popup {
    display: none;
}

.popup-callback {
    position: relative;
    padding: 20px;
    min-width: 260px;
    width: 20vw;
    max-width: 400px;
    background: #fff;
    color: #000;
    margin: auto;
}