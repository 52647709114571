.height-null {
    @include media-breakpoint-down(md) {
        max-height: 0;
        overflow: hidden;
        transition: max-height 1.5s;
    }
}

.height-all {
    @include media-breakpoint-down(md) {
        max-height: 1000px;
        margin-bottom: -20px;
    }
}