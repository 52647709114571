.services {
  display: flex;
  justify-content: space-between;
  padding-bottom: 36px;

  @include media-breakpoint-up(xs) {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
  }

  @include media-breakpoint-up(sm) {
    flex-wrap: wrap;
    flex-direction: row;
    align-items: initial;
  }
  @include media-breakpoint-up(lg) {
    flex-wrap: nowrap;
  }
}